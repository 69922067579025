import React, { useEffect, useState, useRef } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Helmet } from "react-helmet";
import {  useLocation, useNavigate } from "react-router-dom";
import { Localhost_Domain } from "../../constant";
import InputMask from "react-input-mask";
// import 'react-datepicker/dist/react-datepicker.css';
import ReCAPTCHA from "react-google-recaptcha"
import ThankYou from "./ThankYou";
// type ValuePiece = Date | null;

// type Value = ValuePiece | [ValuePiece, ValuePiece];
// import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import MultiDropdown from "../includes/Multiselect";

const ContactUs = ({ }) => {
  function range(start, end, step = 1) {
    const len = Math.floor((end - start) / step) + 1;
    return Array.from({ length: len }, (_, index) => start + index * step);
  }
  const [isThankYouVisible, setThankYouVisible] = useState(false);
  const navigate = useNavigate();
  // const history = useHistory();
  // const token = captchaRef.current.getValue();
  // const [date, setDate] = useState(new Date());
  // const navigate = useNavigate();
  const [state, setState] = useState([
    {
      key: "AL",
      value: "Alabama",
    },
    {
      key: "AK",
      value: "Alaska",
    },
    {
      key: "AZ",
      value: "Arizona",
    },
    {
      key: "AR",
      value: "Arkansas",
    },
    {
      key: "CA",
      value: "California",
    },
    {
      key: "CO",
      value: "Colorado",
    },
    {
      key: "CT",
      value: "Connecticut",
    },
    {
      key: "DE",
      value: "Delaware",
    },
    {
      key: "DC",
      value: "District of Columbia",
    },
    {
      key: "FL",
      value: "Florida",
    },
    {
      key: "GA",
      value: "Georgia",
    },
    {
      key: "HI",
      value: "Hawaii",
    },
    {
      key: "ID",
      value: "Idaho",
    },
    {
      key: "IL",
      value: "Illinois",
    },
    {
      key: "IN",
      value: "Indiana",
    },
    {
      key: "IA",
      value: "Iowa",
    },
    {
      key: "KS",
      value: "Kansas",
    },
    {
      key: "KY",
      value: "Kentucky",
    },
    {
      key: "LA",
      value: "Louisiana",
    },
    {
      key: "ME",
      value: "Maine",
    },
    {
      key: "MD",
      value: "Maryland",
    },
    {
      key: "MA",
      value: "Massachusetts",
    },
    {
      key: "MI",
      value: "Michigan",
    },
    {
      key: "MN",
      value: "Minnesota",
    },
    {
      key: "MS",
      value: "Mississippi",
    },
    {
      key: "MO",
      value: "Missouri",
    },
    {
      key: "MT",
      value: "Montana",
    },
    {
      key: "NE",
      value: "Nebraska",
    },
    {
      key: "NV",
      value: "Nevada",
    },
    {
      key: "NH",
      value: "New Hampshire",
    },
    {
      key: "NJ",
      value: "New Jersey",
    },
    {
      key: "NM",
      value: "New Mexico",
    },
    {
      key: "NY",
      value: "New York",
    },
    {
      key: "NC",
      value: "North Carolina",
    },
    {
      key: "ND",
      value: "North Dakota",
    },
    {
      key: "OH",
      value: "Ohio",
    },
    {
      key: "OK",
      value: "Oklahoma",
    },
    {
      key: "OR",
      value: "Oregon",
    },
    {
      key: "PA",
      value: "Pennsylvania",
    },
    {
      key: "RI",
      value: "Rhode Island",
    },
    {
      key: "SC",
      value: "South Carolina",
    },
    {
      key: "SD",
      value: "South Dakota",
    },
    {
      key: "TN",
      value: "Tennessee",
    },
    {
      key: "TX",
      value: "Texas",
    },
    {
      key: "UT",
      value: "Utah",
    },
    {
      key: "VT",
      value: "Vermont",
    },
    {
      key: "VA",
      value: "Virginia",
    },
    {
      key: "WA",
      value: "Washington",
    },
    {
      key: "WV",
      value: "West Virginia",
    },
    {
      key: "WI",
      value: "Wisconsin",
    },
    {
      key: "WY",
      value: "Wyoming",
    },
  ]);


  const [captchaSettings, setCaptchaSettings] = useState({
    keyname: "Diageo_NAM_Webform_reCAPTCHA",
    fallback: "true",
    orgId: "00D30000000pmHl",
    ts: ""
  });

  const parse = require("html-react-parser");
  const location = useLocation(); // React Hook
  const [showSubmit, setShowSubmit] = useState(false);
  const [gcaptha, setGcaptha] = useState("");
  
  /**
   * Worked for dynamic dropdown
   */

  const captchaRef = useRef(null);
  const [disableSubmit,setDisableSubmit] = useState(true);
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const inquriyOptionsDataUrl = Localhost_Domain + "/data/contactUsOptions.json";

  const [inquiryOptions, setInquiryOptions] = useState([]);
  const [selectedInquiryOption, setSelectedInquiryOption] = useState('');

  const [reasonConactOptions, setReasonConactOptions] = useState([]);

  const [rConactOptions, setRConactOptions] = useState([]);
  const [selectedReaconConactOption, setSelectedReaconConactOption] = useState('');

  const [renderFromInputVar, setRenderFromInputVar] = useState(0);

  const checkRoute = location.pathname;
  let routUrl = Localhost_Domain + checkRoute;
  const [responseApi, setResponseApi] = useState({});

  const [gToken, setGToken] = useState("");
  const [isGtoken, setIsGToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const verify = (response) => {
    // console.log(response);
    const timestamp = new Date().getTime();

    const isExpired = captchaRef.current?.getValue() === '';
    if (isExpired) {
      console.log('Captcha Expired');
      // Disable the submit or perform any other action when reCAPTCHA is expired
      setDisableSubmit(true);
      return;
    }
    // Modify the value as needed
    setDisableSubmit(false);
    
    setGToken(response);
    const newValue = {
      keyname: "Diageo_NAM_Webform_reCAPTCHA",
      fallback: "true",
      orgId: "00D30000000pmHl",
      ts: timestamp.toString()
    };

    // Set the new value in the state
    setCaptchaSettings(newValue);
    setGcaptha(response);
    setIsRecaptchaVerified(true);
    setErrorMessage('');
  };
  const handleCallback = (childData) => {
    //setShowSubmit(childData);
  };

  const handleSelectChange = (event) => {

    const selectedValue = event.target.value;

    // Set the form field value using formik.setFieldValue
    formik.setFieldValue("00N4v00000C2h5k", selectedValue);
    setSelectedInquiryOption(selectedValue);
    setRenderFromInputVar(0);
    // Reset other form field values when empty string is selected
    if (selectedValue === '') {
      setSelectedInquiryOption('');
      setSelectedReaconConactOption('');
      setRenderFromInputVar(0);
    } else {
      // alert("hello");
      const foundType = inquiryOptions.find(item => item.value === selectedValue);
      const filteredReasonData = reasonConactOptions.filter(item => item.inquiryTypes && item.
        inquiryTypes.includes(parseInt(foundType.id, 10)));
        
      setRConactOptions(filteredReasonData);
    }
  };

  const handleReasonSelectChange = (event) => {
    const selectedValue = event.target.value;
    // Set the form field value using formik.setFieldValue
    formik.setFieldValue("00N4v00000C2h5p", selectedValue);
    setSelectedReaconConactOption(selectedValue);

    const foundReasonIndex = rConactOptions.find(
      item => item.type === selectedValue
    );

    if (selectedValue === '') {
      setRenderFromInputVar(0);
    } else {
      setRenderFromInputVar(foundReasonIndex.id);
    }

  };

 const date = new Date().toISOString().split("T")[0]; 
 function minDate() {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 125);
    return date.toISOString().split("T")[0];
  }

  function differenceInYears(inputDate) {
    const ageDifMs = Date.now() - inputDate.getTime();
    if (new Date().getFullYear() < inputDate.getFullYear()) {
      return 0;
    }
    const ageDate = new Date(ageDifMs); // miliseconds from current and input date
    const ageInYears = Math.abs(ageDate.getUTCFullYear() - 1970); //calculate age
    return ageInYears;
  }

  useEffect(() => {
    const fetchInquriyData = async () => {
      try {
        const response = await fetch(inquriyOptionsDataUrl, {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        });
        const jsonData = await response.json();
        console.log(jsonData);
        setInquiryOptions(jsonData.inquiryTypes);
        setReasonConactOptions(jsonData.reasonForContact);
        //console.log(reasonConactOptions);
        // setLoading(false);
        //console.log(jsonData.inquiryTypes);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setLoading(false);
        // Handle errors if needed
      } finally {
        setLoading(false); // Set loading to false after data is fetched or if there's an error
      }
    };
    fetchInquriyData();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Check if reCAPTCHA is expired
      const isExpired = captchaRef.current?.getValue() === '';

      if (isExpired) {
        // Handle expiration, you can call your custom function here
        console.log('reCAPTCHA expired');
        // ... your custom logic for expiration
      }
    }, 1000); // Check every second, adjust as needed

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    // setTimeout(
    //   () => {
    //     if (window.grecaptcha.ready) {
    //       window.grecaptcha.execute('6Lf2_uoaAAAAAHmbdTSGUgSTIT2whOY1sDXARlO7', { action: 'submit' }).then(token => {
    //         // submitData(token);
    //         // console.log(token)
    //         setGToken(token);
    //         setIsGToken(true);
    //       });
    //     }
    //   },
    //   5000);
  }, []);

  function invalidDateCheck(inputDate) {
    const ageDifMs = Date.now() - inputDate.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from current and input date
    const ageInYears = Math.abs(ageDate.getUTCFullYear() - 1970); //calculate age
    return ageInYears;
  }

  const contactUsSchema = Yup.object().shape({
    "00N1B00000B8U4J": Yup.string()
      .max(50, "First Name is Too Long!")
      .matches(
        /^[a-zA-Z]+$/,
        "First Name cannot contain white space and special character"
      )
      .required("First Name is required."),
    "00N1B00000B8U4K": Yup.string()
      .max(80, "Last Name is Too Long!")
      .matches(
        /^[a-zA-Z]+$/,
        "Last Name cannot contain white space and special character"
      )
      .required("Last Name is required."),
    "00N4v00000BqSVA": Yup.date()
      .required("DOB is required.")
      .test(
        "00N4v00000BqSVA",
        "You must be at least 21 years old to enter.",
        function (value) {
          return differenceInYears(new Date(value)) >= 21;
        }
      )
      .test("00N4v00000BqSVA", "Invalid Date.", function (value) {
        return invalidDateCheck(new Date(value)) < 125;
      }),

    "00N4v00000C2nYW": Yup.string()
      .max(120, "Email is Too Long.")
      .email('Invalid email.')
      .required("Email is required."),

    "00N4v00000C2nYb": Yup.string()
      .max(120, "Verify Email is Too Long.")
      .oneOf([Yup.ref('00N4v00000C2nYW'), null], 'Verify Email must match with Email field.')
      .email('Invalid verify email.')
      .required("Verify Email is required."),

    "00N4v00000C2nYU": Yup.string()
      .max(100, "City is Too Long.")
      .matches(
        /^[a-zA-Z]/,
        "City cannot special character."
      )
      .required("City is required."),

    "00N4v00000C2nYa": Yup.string()
      .required("State is required."),

    "00N4v00000C2h5k": Yup.string()
      .required("Inquiry Type is required."),

    "00N4v00000C2h5p": Yup.string()
      .required("Reason For Contacting Us is required."),

    "00N4v00000C2nYc": Yup.string().when([], {
      is: () => renderFromInputVar === '4' || renderFromInputVar === '6' || renderFromInputVar === '7',
      then: Yup.string().required('Phone is required.'),
    }),

    "00N4v00000BqS6K": Yup.string().when([], {
      is: () => renderFromInputVar === '7',
      then: Yup.string().required('Publication Name is required.'),
    }),

    "00N3000000AY5Wa": Yup.string().when([], {
      is: () => renderFromInputVar === '6',
      then: Yup.string().required('Store Name is required.'),
    }),

    "00N3000000AYgQ9": Yup.string().when([], {
      is: () => renderFromInputVar === '6',
      then: Yup.string().required('Store Address is required.'),
    }),

    "00N4v00000C2nYY": Yup.string().when([], {
      is: () => renderFromInputVar === '9',
      then: Yup.string().required('Order Number is required.'),
    }),

    "00N4v00000C2nYX": Yup.string()
      .max(1000, "Inquiry Description needs to lessthan or equal to 1000 charactors.")
      .required("Inquiry Description is required."),
    "00N1B00000BDRxP": Yup.bool().oneOf(
      [true],
      "You must accept the Privacy and Cookies Information."
    ),
    "captcha_settings": Yup.object(),
    G_Token: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      "00N1B00000B8U4J": "",
      "00N1B00000B8U4K": "",
      "00N4v00000BqSVA": "",
      "00N4v00000C2nYW": "",
      "00N4v00000C2nYb": "",
      "00N4v00000C2nYU": "",
      "00N4v00000C2nYa": "",
      "00N4v00000C2h5k": "",
      "00N4v00000C2h5p": "",
      "00N4v00000C2nYX": "",
      "00N1B00000BDRxP": false,
      "00N4v00000C2nYV": "US",
      "00N4v00000C2nYc": "",
      "00N4v00000BqS6K": "",
      "00N3000000AY5Wa": "",
      "00N3000000AYgQ9": "",
      "00N4v00000C2nYY": "",
      "00N1B00000BDRwq": [],
      "00N4v00000BqS6F":Localhost_Domain,
      origid: "00D30000000pmHl",
      retURL: Localhost_Domain + "/salesforce/thank-you"
    },
    validate: (values) => {
      const errors = {};

      // Custom validation logic for the selected options
      if (renderFromInputVar === '4' ) {
        if (values["00N1B00000BDRwq"].length === 0) {
          errors["00N1B00000BDRwq"] = 'Please select at least one option.';
        }
      }


      // You can add more validation logic for the selected options here if needed

      return errors;
    },
    validationSchema: contactUsSchema,
    onSubmit: (values, { resetForm }) => {
      
      var dataForm = {
      
        Data: {
          "00N1B00000B8U4J": values["00N1B00000B8U4J"],//first name
          "00N1B00000B8U4K": values["00N1B00000B8U4K"],//last name
          "00N4v00000BqSVA": values["00N4v00000BqSVA"],//dob
          "00N4v00000C2nYW": values["00N4v00000C2nYW"],//email
          "00N4v00000C2nYb": values["00N4v00000C2nYb"],//verify email
          "00N4v00000C2nYU": values["00N4v00000C2nYU"],//city
          "00N4v00000C2nYa": values["00N4v00000C2nYa"],//state
          "00N4v00000C2h5k": values["00N4v00000C2h5k"],//inquiryTpye
          "00N4v00000C2h5p": values["00N4v00000C2h5p"],//contactReason
          "00N4v00000C2nYX": values["00N4v00000C2nYX"],//inquiryDescription
          "00N4v00000C2nYc": values["00N4v00000C2nYc"],//phone
          "00N4v00000BqS6K": values["00N4v00000BqS6K"],//publicationName
          "00N4v00000C2nYV": values["00N4v00000C2nYV"],//local
          "00N3000000AY5Wa": values["00N3000000AY5Wa"],//storeName
          "00N3000000AYgQ9": values["00N3000000AYgQ9"],//storeAddress
          "00N4v00000C2nYY": values["00N4v00000C2nYY"],//orderNumber
          "00N1B00000BDRwq": values["00N1B00000BDRwq"],//productPickList
          "00N4v00000BqS6F": values["00N4v00000BqS6F"],//webSiteName
          origid: "00D30000000pmHl",//Orginisation id
          "00N1B00000BDRxP": values["00N1B00000BDRxP"],//privacyCoockies
          retURL: Localhost_Domain + "/salesforce/thank-you",//return URL
          G_Token: gToken,//token
        }
      };

    //   const salesforceData = {
    //         "00N1B00000B8U4J": values["00N1B00000B8U4J"],//first name
    //         "00N1B00000B8U4K": values["00N1B00000B8U4K"],//last name
    //         "00N4v00000BqSVA": values["00N4v00000BqSVA"],//dob
    //         "00N4v00000C2nYW": values["00N4v00000C2nYW"],//email
    //         "00N4v00000C2nYb": values["00N4v00000C2nYb"],//verify email
    //         "00N4v00000C2nYU": values["00N4v00000C2nYU"],//city
    //         "00N4v00000C2nYa": values["00N4v00000C2nYa"],//state
    //         "00N4v00000C2h5k": values["00N4v00000C2h5k"],//inquiryTpye
    //         "00N4v00000C2h5p": values["00N4v00000C2h5p"],//contactReason
    //         "00N4v00000C2nYX": values["00N4v00000C2nYX"],//inquiryDescription
    //         "00N4v00000C2nYc": values["00N4v00000C2nYc"],//phone
    //         "00N4v00000BqS6K": values["00N4v00000BqS6K"],//publicationName
    //         "00N4v00000C2nYV": values["00N4v00000C2nYV"],//local
    //         "00N3000000AY5Wa": values["00N3000000AY5Wa"],//storeName
    //         "00N3000000AYgQ9": values["00N3000000AYgQ9"],//storeAddress
    //         "00N4v00000C2nYY": values["00N4v00000C2nYY"],//orderNumber
    //         "00N1B00000BDRwq": values["00N1B00000BDRwq"],//productPickList
    //         "00N4v00000BqS6F": values["00N4v00000BqS6F"],//webSiteName
    //         "origid": "00D30000000pmHl",//Orginisation id
    //         "00N1B00000BDRxP": values["00N1B00000BDRxP"],//privacyCoockies
    //         "retURL": Localhost_Domain + "/salesforce/thank-you",//return URL
    //         "G_Token": gToken,//token
    //   }

      if (isGtoken) {
        dataForm.Data.G_Token = gToken;
      }
        // console.log(gToken);
      var dataGForm = {
        Data: {
          G_Token: gToken,
        }
      }

       // Create a form dynamically
    // const form = document.createElement('form');
    // form.method = 'POST';
    // form.action = 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8';

     // Add Salesforce Web-to-Case fields as hidden inputs
    //  for (const fieldName in salesforceData) {
    //     const input = document.createElement('input');
    //     input.name = fieldName;
    //     input.value = salesforceData[fieldName];
    //     input.type = 'hidden';
    //     form.appendChild(input);
    //   }
  
      // Add the form to the document and submit it
    //   document.body.appendChild(form);
    //   form.submit();

      let eHheader = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        withCredentials: true,
        crossdomain: true,
        methods: ['POST', 'GET', 'PATCH', 'DELETE', 'OPTIONS'],
        allowedHeaders: ['Content-Type', 'Authorization'],
      }
      axios
        .post("api/salesForceAPI.php", dataForm, eHheader)
        .then(function (result) {
          console.log(result);
          if(result){
            setThankYouVisible(true);
            navigate('/thank-you');
          }
          //return result;
        })
        .catch(function (e) {
          console.log(e);
        });
      },
    },
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Convert the input value to uppercase before updating the state
    formik.handleChange(e);
    formik.setFieldValue(name, value.toUpperCase());
  };
  const handleInputChangeL = (e) => {
    const { name, value } = e.target;
    // Convert the input value to uppercase before updating the state
    formik.handleChange(e);
    formik.setFieldValue(name, value.toLowerCase());
  };

  // const [selectedMOptions, setSelectedMOptions] = useState([]); // State to hold selected options

  // const handleMultiDropdownChange = (selectedValues) => {
  //   setSelectedMOptions(selectedValues);
  // };

  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="metaDescription" />
        <meta property="og:title" content="title" />
        <meta property="og:description" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="test" />
        <meta
          property="og:image"
          content={`${Localhost_Domain + "/assets/images/FB_social.jpg"}`}
        />
        <meta name="twitter:card" content="" />
        <meta name="twitter:url" content="https://www.obanwhisky.comform" />
        <meta name="twitter:title" content="title" />
        <meta
          name="twitter:image"
          content={`${Localhost_Domain + "/assets/images/TW_social.jpg"}`}
        />
      </Helmet>
      <main className="main newsLetter-page">
        {isThankYouVisible && isThankYouVisible === true ? (
          <ThankYou/>
        ) : (
          <section className="newsLetter-container newsLetter">
            <Container>
              <div className="justify-content-center stayTouch">
                <div className="d-flex justify-content-center text-align-center">
                  <h1 className="text-dark"><span class="text-danger">C</span>ontact Us</h1>
                </div>
                {/* <p className="text-center">
                  Sign up today for exclusive updates from our team, direct from
                  the distillery.
                </p> */}
                <p className="text-center">Fields marked with * are required</p>
              </div>

              <div className="justify-content-center mt-5">
                <Form className="touchForm" method="POST" id="salesforceForm" action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" encType="application/x-www-form-urlencoded">
                  <Row>
                    {/* <Form.Group className="input" controlId="HD_PromoCode">
                      <Form.Control
                        type="hidden"
                        name="HD_PromoCode"
                        value="DNA107FCM0721WA0015"
                      />
                    </Form.Group> */}

                    {/* <Form.Group
                      className="mb-3 input"
                      controlId="HD_Consumerid"
                    >
                      <Form.Control
                        type="hidden"
                        name="HD_Consumerid"

                      />
                    </Form.Group> */}
                    <Form.Group className="input" controlId="00N4v00000BqS6F">
                      <Form.Control
                        type="hidden"
                        name="00N4v00000BqS6F"
                        value={Localhost_Domain}
                      />
                    </Form.Group>
                   
                    <Col sm={6} xs={12}>
                      <Form.Group
                        className="mb-3 input"
                        controlId="00N1B00000B8U4J"
                      >
                        {/* <Form.Label>
                          <b>First Name*</b>
                        </Form.Label> */}
                        <Form.Control
                          name="00N1B00000B8U4J"
                          value={formik.values["00N1B00000B8U4J"]}
                          maxLength="80"
                          //onChange={formik.handleChange("FirstName")}
                          onChange={handleInputChange}
                          placeholder="First Name*"
                        />
                        {formik.errors["00N1B00000B8U4J"] &&
                          formik.touched["00N1B00000B8U4J"] ? (
                          <span>{formik.errors["00N1B00000B8U4J"]}</span>
                        ) : null}
                      </Form.Group>
                    </Col>

                    <Col sm={6} xs={12}>
                      <Form.Group
                        className="mb-3 input"
                        controlId="00N1B00000B8U4K"
                      >
                        {/* <Form.Label>
                          <b>Last Name*</b>
                        </Form.Label> */}
                        <Form.Control
                          name="00N1B00000B8U4K"
                          value={formik.values["00N1B00000B8U4K"]}
                          maxLength="50"
                          placeholder="Last Name*"
                          // onChange={formik.handleChange("LastName")}
                          onChange={handleInputChange}
                        />
                        {formik.errors["00N1B00000B8U4K"] &&
                          formik.touched["00N1B00000B8U4K"] ? (
                          <span>{formik.errors["00N1B00000B8U4K"]}</span>
                        ) : null}
                      </Form.Group>
                    </Col>

                    <Col sm={6} xs={12}>
                      <Form.Group
                        className="mb-3 input"
                        as={Col}
                        controlId="00N4v00000C2nYW"
                      >
                        <Form.Control
                          name="00N4v00000C2nYW"
                          type="email"
                          value={formik.values["00N4v00000C2nYW"]}
                          maxLength="100"
                          //onChange={formik.handleChange("Email")}
                          onChange={handleInputChangeL}
                          placeholder="Email*"
                        />
                        {formik.errors["00N4v00000C2nYW"] &&
                          formik.touched["00N4v00000C2nYW"] ? (
                          <span>{formik.errors["00N4v00000C2nYW"]}</span>
                        ) : null}
                      </Form.Group>
                      {/* <Form.Group
                        className="mb-3 input"
                        controlId="HD_EmailType"
                      >
                        <Form.Control
                          name="HD_EmailType"
                          type="hidden"
                          value="1"
                          runat="server"
                        />
                      </Form.Group> */}

                      <Form.Group
                        className="mb-3 input"
                      // controlId="00N4v00000C2nYV"
                      >
                        <Form.Control
                          name="captcha_settings"
                          type="hidden"
                          value={JSON.stringify(captchaSettings)}
                          runat="server"
                        />
                        
                      </Form.Group>
                      
                      <Form.Group
                        className="mb-3 input"
                      // controlId="00N4v00000C2nYV"
                      >
                        <Form.Control
                          name="debug"
                          type="hidden"
                          value='1'
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 input"
                      // controlId="00N4v00000C2nYV"
                      >
                        <Form.Control
                          name="orgId"
                          type="hidden"
                          value="00D30000000pmHl"
                          runat="server"
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 input"
                      // controlId="00N4v00000C2nYV"
                      >
                        <Form.Control
                          name="00N4v00000C2nYV"
                          type="hidden"
                          value="USA"
                        />
                      </Form.Group>


                      {/* <Form.Group
                        className="mb-3 input"
                      >
                        <Form.Control
                          name="g-recaptcha-response"
                          type="hidden"
                          value={gcaptha}
                          runat="server"
                        />
                      </Form.Group> */}

                      <Form.Group
                        className="mb-3 input"
                      // controlId="00N4v00000C2nYV"
                      >
                        <Form.Control
                          name="retURL"
                          type="hidden"
                          value={Localhost_Domain+"/salesforce/thank-you"}
                          runat="server"
                        />
                      </Form.Group>

                    </Col>

                    <Col sm={6} xs={12}>
                      <Form.Group
                        className="mb-3 input"
                        as={Col}
                        controlId="00N4v00000C2nYb"
                      >
                        <Form.Control
                          name="00N4v00000C2nYb"
                          type="email"
                          value={formik.values["00N4v00000C2nYb"]}
                          maxLength="100"
                          // onChange={formik.handleChange("VerifyEmail")}
                          onChange={handleInputChangeL}
                          placeholder="Verify Email*"
                        />
                        {formik.errors["00N4v00000C2nYb"] &&
                          formik.touched["00N4v00000C2nYb"] ? (
                          <span>{formik.errors["00N4v00000C2nYb"]}</span>
                        ) : null}
                      </Form.Group>

                    </Col>

                    {/* <Col sm={12} xs={12}>
                      <Form.Group className="mb-3 input" controlId="CP_DOB">
                        <Form.Label>
                          <b>Date of Birth*</b>
                        </Form.Label>

                        <Form.Control
                          name="00N4v00000BqSVA"
                          className={
                            formik.values.CP_DOB ? "rem-placeholder" : ""
                          }
                          type="date"
                          value={formik.values.CP_DOB}
                          onChange={formik.handleChange("CP_DOB")}
                          placeholder="dd-mm-yyyy*"
                          max={date}
                          min={minDate()}
                        />
                        {formik.errors.CP_DOB && formik.touched.CP_DOB ? (
                          <span>{formik.errors.CP_DOB}</span>
                        ) : null}
                      </Form.Group>
                    </Col> */}
                    
                    <Col sm={6} xs={12}>
                      <Form.Group className="mb-3 input" controlId="00N4v00000BqSVA">
                        <Form.Control
                          name="00N4v00000BqSVA"
                          className={
                            formik.values["00N4v00000BqSVA"] ? "rem-placeholder" : ""
                          }
                          type="date"
                          value={formik.values["00N4v00000BqSVA"]}
                          onChange={formik.handleChange("00N4v00000BqSVA")}
                          placeholder="dd-mm-yyyy*"
                          max={date}
                          min={minDate()}
                        />
                        {formik.errors["00N4v00000BqSVA"] && formik.touched["00N4v00000BqSVA"] ? (
                          <span>{formik.errors["00N4v00000BqSVA"]}</span>
                        ) : null}
                      </Form.Group>
                    </Col>

                    <Col sm={6} xs={12}>
                      <Form.Group
                        className="mb-3 input"
                        as={Col}
                        controlId="00N4v00000C2nYU"
                      >
                        <Form.Control
                          name="00N4v00000C2nYU"
                          type="text"
                          value={formik.values["00N4v00000C2nYU"]}
                          maxLength="100"
                          //onChange={formik.handleChange("City")}
                          onChange={handleInputChange}
                          placeholder="City*"
                        />
                        {formik.errors["00N4v00000C2nYU"] &&
                          formik.touched["00N4v00000C2nYU"] ? (
                          <span>{formik.errors["00N4v00000C2nYU"]}</span>
                        ) : null}
                      </Form.Group>
                    </Col>
                    {/* state with abbrivation */}
                    <Col sm={6} xs={12}>
                      <Form.Group
                        className="mb-3 input"
                        as={Col}
                        controlId="00N4v00000C2nYa"
                      >
                        <Form.Select
                          className="mb-3"
                          aria-label="Select A State"
                          name="00N4v00000C2nYa"
                          onChange={formik.handleChange(
                            "00N4v00000C2nYa"
                          )}
                        >

                          <option value="">Select A State*</option>
                          {state.length > 0 &&
                            state.map((option) => (
                              <option key={option.id} value={option.key}>
                                {option.value}
                              </option>
                            ))}
                        </Form.Select>
                        {formik.errors["00N4v00000C2nYa"] &&
                          formik.touched["00N4v00000C2nYa"] ? (
                          <span className="required_text">
                            {formik.errors["00N4v00000C2nYa"]}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Col>

                    <Col sm={6} xs={12}>
                      <Form.Group
                        className="mb-3 input"
                        as={Col}
                        controlId="00N4v00000C2h5k"
                      >
                        <Form.Select
                          name="00N4v00000C2h5k"
                          className="mb-3"
                          aria-label="Inquiry Type"
                          onChange={handleSelectChange}
                          value={selectedInquiryOption}
                        >
                          <option value="">Select Inquiry Type*</option>
                          {inquiryOptions.length > 0 &&
                            inquiryOptions.map((option) => (
                              <option key={option.id} value={option.value}>
                                {option.type}
                              </option>
                            ))}
                        </Form.Select>
                        {formik.errors["00N4v00000C2h5k"] &&
                          formik.touched["00N4v00000C2h5k"] ? (
                          <span className="required_text">
                            {formik.errors["00N4v00000C2h5k"]}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Col>

                    <Col sm={12} xs={12}>

                      <Form.Group
                        className="mb-3 input"
                        as={Col}
                        controlId="00N4v00000C2h5p"
                      >
                        <Form.Select
                          className="mb-3"
                          aria-label="Contact Reason"
                          name="00N4v00000C2h5p"
                          onChange={handleReasonSelectChange}
                          value={selectedReaconConactOption}
                          placeholder="Reason For Contacting Us*"
                        >
                          <option value="">Reason For Contacting Us*</option>
                          {
                            rConactOptions.length > 0 &&
                            rConactOptions.map((option) => (
                              <option key={option.id} value={option.type}>
                                {option.type}
                              </option>
                            ))
                          }

                        </Form.Select>
                        {formik.errors["00N4v00000C2h5p"] &&
                          formik.touched["00N4v00000C2h5p"] ? (
                          <span className="required_text">
                            {formik.errors["00N4v00000C2h5p"]}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Col>

                    {(renderFromInputVar === '4' || renderFromInputVar === '6' ||renderFromInputVar === '7') && ( // Render input fields based on selected option
                      <Col sm={12} xs={12}>
                        <Form.Group className="mb-3 input" controlId="00N4v00000C2nYc" >
                          {/* < Form.Control */}
                          <InputMask
                            type="tel"
                            className="form-control"
                            mask="(999) 999-9999"
                            placeholder="Phone*"
                            maskChar=" "
                            name="00N4v00000C2nYc"
                            value={formik.values["00N4v00000C2nYc"]}
                            onChange={formik.handleChange("00N4v00000C2nYc")}
                          />

                          {formik.errors["00N4v00000C2nYc"] &&
                            formik.touched["00N4v00000C2nYc"] ? (
                            <span>{formik.errors["00N4v00000C2nYc"]}</span>
                          ) : null}
                        </Form.Group>


                      </Col>

                    )}

                    {(renderFromInputVar === '4' ) && ( // Render input fields based on selected option
                    <>
                     <Form.Group 
                     ><Form.Control name="00N4v00000BqS6K" type="hidden" value=""/></Form.Group>
                       <Form.Group ><Form.Control name="00N3000000AY5Wa" type="hidden" value=""/></Form.Group>
                       <Form.Group ><Form.Control name="00N3000000AYgQ9" type="hidden" value=""/></Form.Group>
                       <Form.Group ><Form.Control name="00N4v00000C2nYY" type="hidden" value=""/></Form.Group>
                    </>
                      
                    )}

{(renderFromInputVar === '1' ) && ( // Render input fields based on selected option
                    <>
                     <Form.Group 
                     ><Form.Control name="00N4v00000C2nYc" type="hidden" value=""/></Form.Group>
                     <Form.Group 
                     ><Form.Control name="00N4v00000BqS6K" type="hidden" value=""/></Form.Group>
                      <Form.Group 
                     ><Form.Control name="00N1B00000BDRwq" type="hidden" value=""/></Form.Group>
                       <Form.Group ><Form.Control name="00N3000000AY5Wa" type="hidden" value=""/></Form.Group>
                       <Form.Group ><Form.Control name="00N3000000AYgQ9" type="hidden" value=""/></Form.Group>
                       <Form.Group ><Form.Control name="00N4v00000C2nYY" type="hidden" value=""/></Form.Group>
                    </>
                      
                    )}


{(renderFromInputVar === '2' ) && ( // Render input fields based on selected option
                    <>
                     <Form.Group 
                     ><Form.Control name="00N4v00000C2nYc" type="hidden" value=""/></Form.Group>
                     <Form.Group 
                     ><Form.Control name="00N4v00000BqS6K" type="hidden" value=""/></Form.Group>
                     
                       <Form.Group><Form.Control name="00N3000000AY5Wa" type="hidden" value=""/></Form.Group>
                       <Form.Group><Form.Control name="00N3000000AYgQ9" type="hidden" value=""/></Form.Group>
                       <Form.Group><Form.Control name="00N4v00000C2nYY" type="hidden" value=""/></Form.Group>
                    </>
                      
                    )}

                {(renderFromInputVar === '6' ) && ( // Render input fields based on selected option
                    <>
                     <Form.Group ><Form.Control name="00N4v00000BqS6K" type="hidden" value=""/></Form.Group>
                       <Form.Group ><Form.Control name="00N3000000AY5Wa" type="hidden" value=""/></Form.Group>
                       <Form.Group ><Form.Control name="00N3000000AYgQ9" type="hidden" value=""/></Form.Group>
                       <Form.Group ><Form.Control name="00N4v00000C2nYY" type="hidden" value=""/></Form.Group>

                       {/* <Col sm={12} xs={12}>
                        <Form.Group className="mb-3 input" controlId="00N4v00000C2nYc" >
                         
                          <InputMask
                            type="tel"
                            className="form-control"
                            mask="(999) 999-9999"
                            placeholder="Phone*"
                            maskChar=" "
                            name="00N4v00000C2nYc"
                            value={formik.values["00N4v00000C2nYc"]}
                            onChange={formik.handleChange("00N4v00000C2nYc")}
                          />

                          {formik.errors["00N4v00000C2nYc"] &&
                            formik.touched["00N4v00000C2nYc"] ? (
                            <span>{formik.errors["00N4v00000C2nYc"]}</span>
                          ) : null}
                        </Form.Group>
                      </Col> */}
                      <Col sm={12} xs={12}>
                          <Form.Group className="mb-3 input" controlId="00N3000000AY5Wa">
                            <Form.Control
                              name="00N3000000AY5Wa"
                              placeholder="Store Name*"
                              value={formik.values["00N3000000AY5Wa"]}
                              onChange={handleInputChange}
                            //onChange={formik.handleChange("Store_Name")}
                            />
                            {formik.errors["00N3000000AY5Wa"] &&
                              formik.touched["00N3000000AY5Wa"] ? (
                              <span>{formik.errors["00N3000000AY5Wa"]}</span>
                            ) : null}
                          </Form.Group>
                        </Col>
                       <Col sm={12} xs={12}>
                          <Form.Group className="mb-3 input" controlId="00N3000000AYgQ9">
                            <Form.Control
                              name="00N3000000AYgQ9"
                              placeholder="Store Address*"
                              value={formik.values["00N3000000AYgQ9"]}
                              onChange={handleInputChange}
                            // onChange={formik.handleChange("Store_Address")}
                            />
                            {formik.errors["00N3000000AYgQ9"] &&
                              formik.touched["00N3000000AYgQ9"] ? (
                              <span>{formik.errors["00N3000000AYgQ9"]}</span>
                            ) : null}
                          </Form.Group>
                        </Col>
                    </>
                      
                    )}

              {(renderFromInputVar === '3' ) && ( // Render input fields based on selected option
                    <>
                     <Form.Group ><Form.Control name="00N4v00000C2nYc" type="hidden" value=""/></Form.Group>
                     <Form.Group ><Form.Control name="00N4v00000BqS6K" type="hidden" value=""/></Form.Group>
                       <Form.Group ><Form.Control name="00N3000000AY5Wa" type="hidden" value=""/></Form.Group>
                       <Form.Group ><Form.Control name="00N3000000AYgQ9" type="hidden" value=""/></Form.Group>
                       <Form.Group ><Form.Control name="00N4v00000C2nYY" type="hidden" value=""/></Form.Group>
                    </>
                      
                    )}  

          {(renderFromInputVar === '9' ) && ( // Render input fields based on selected option
                    <>
                     <Form.Group ><Form.Control name="00N4v00000C2nYc" type="hidden" value=""/></Form.Group>
                     <Form.Group ><Form.Control name="00N4v00000BqS6K" type="hidden" value=""/></Form.Group>
                     <Form.Group ><Form.Control name="00N1B00000BDRwq" type="hidden" value=""/></Form.Group>
                       <Form.Group ><Form.Control name="00N3000000AY5Wa" type="hidden" value=""/></Form.Group>
                       <Form.Group ><Form.Control name="00N3000000AYgQ9" type="hidden" value=""/></Form.Group>
                    </>
                    )}  


                    {(
                      renderFromInputVar === '7') && ( // Render input fields based on selected option
                        <Col sm={12} xs={12}>
                          <Form.Group className="mb-3 input" controlId="00N4v00000BqS6K" >
                            <Form.Control
                              type="text"
                              placeholder="Publication Name*"
                              value={formik.values["00N4v00000BqS6K"]}
                              name="00N4v00000BqS6K"
                              // onChange={formik.handleChange("Publication_Name")}
                              onChange={handleInputChange}
                            />
                            {formik.errors["00N4v00000BqS6K"] &&
                              formik.touched["00N4v00000BqS6K"] ? (
                              <span>{formik.errors["00N4v00000BqS6K"]}</span>
                            ) : null}
                          </Form.Group>
                      {/* if 7 than hidden */}
                      <Form.Group
                        className="mb-3 input"
                      // controlId="00N4v00000C2nYV"
                      >
                        <Form.Control
                          name="00N1B00000BDRwq"
                          type="hidden"
                          value=""
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 input"
                      // controlId="00N4v00000C2nYV"
                      >
                        <Form.Control
                          name="00N4v00000C2nYY"
                          type="hidden"
                          value=""
                        />
                      </Form.Group>
                        </Col>
                      )}
                    {(renderFromInputVar === '2' || renderFromInputVar === '3' || renderFromInputVar === '4' ) && ( // Render input fields based on selected option
                      <Col sm={12} xs={12}>
                        <Form.Group
                          className="mb-3 input multselect-dropdown"
                        >
                          <MultiDropdown
                            name="00N1B00000BDRwq"
                            controlId="00N1B00000BDRwq"
                            selectedOptions={formik.values["00N1B00000BDRwq"]}
                            onChange={(Product_Picklist) => formik.setFieldValue('00N1B00000BDRwq', Product_Picklist)} // Set Product_Picklist directly
                          />
                          {formik.errors["00N1B00000BDRwq"] && formik.touched["00N1B00000BDRwq"] ? (
                            <span>{formik.errors["00N1B00000BDRwq"]}</span>
                          ) : null}
                        </Form.Group>
                      </Col>
                    )}

                   
                    {(
                      renderFromInputVar === '9') && ( // Render input fields based on selected option  
                        <Col sm={12} xs={12}>
                          <Form.Group className="mb-3 input" controlId="00N4v00000C2nYY">
                            <Form.Control
                              name="00N4v00000C2nYY"
                              placeholder="Order Number*"
                              value={formik.values.Order_Number}
                              // onChange={formik.handleChange("Order_Number")}
                              onChange={handleInputChange}
                            />
                            {formik.errors["00N4v00000C2nYY"] &&
                              formik.touched["00N4v00000C2nYY"] ? (
                              <span>{formik.errors["00N4v00000C2nYY"]}</span>
                            ) : null}
                          </Form.Group>
                        </Col>
                      )}

                    <Col sm={12} xs={12}>
                      <Form.Group className="mb-3 input" controlId="00N4v00000C2nYX">
                        <Form.Control
                          name="00N4v00000C2nYX"
                          placeholder="Inquiry Description*"
                          value={formik.values["00N4v00000C2nYX"]}
                          // onChange={formik.handleChange("Inquiry_Description")}
                          onChange={handleInputChange}
                          as="textarea"
                          rows={2} />
                        {formik.errors["00N4v00000C2nYX"] &&
                          formik.touched["00N4v00000C2nYX"] ? (
                          <span>{formik.errors["00N4v00000C2nYX"]}</span>
                        ) : null}
                      </Form.Group>
                    </Col>

                    <Col sm={12} xs={12}>
                      <Form.Group className="input">
                        <Form.Check
                          name="00N1B00000BDRxP"
                          type="checkbox"
                          value={formik.values["00N1B00000BDRxP"]}
                          checked={formik.values["00N1B00000BDRxP"]}
                          id="00N1B00000BDRxP"
                          onChange={formik.handleChange(
                            "00N1B00000BDRxP"
                          )}
                          label={parse(
                            "* I accept Diageo's <a class='red-color' href='https://footer.diageohorizon.com/dfs/assets/www.oldparrscotch.com/TnC_uen.html?locale=uen-us' target='_blank' style='color: black; text-decoration: underline'>Conditions of Use</a> and acknowledge the <a class='red-color'  href='https://footer.diageohorizon.com/dfs/assets/www.oldparrscotch.com/PrivacyPolicy_uen.html?locale=uen-us' style='color: black; text-decoration: underline' target='_blank'>Privacy & Cookie Notice</a>."
                          )}
                        />
                        {formik.errors["00N1B00000BDRxP"] &&
                          formik.touched["00N1B00000BDRxP"] ? (
                          <span>{formik.errors["00N1B00000BDRxP"]}</span>
                        ) : null}
                      </Form.Group>
                    </Col>
                    
                    <Form.Group>
                    <div className="recapta-group">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        ref={captchaRef}
                        onChange={verify} 
                        // size="invisible"
                      />
                      </div>
                    </Form.Group>
                    {errorMessage && <div id="error"><span>{errorMessage}</span></div>}
                    <Form.Group className="btnDiv">
                      <Button
                        className="btn"
                        type="submit"
                        disabled={disableSubmit}
                        onClick={formik.handleSubmit}
                      >
                        Send Message
                      </Button>
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            </Container>
          </section>
        )}
      </main>
    </>
  );
};
export default ContactUs;
